import React, { Component } from "react";
import "./index.css";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

class Menu extends Component {
  state = {
    currentUserName: "",
    currentUserEmail: "",
  };

  componentDidMount() {
    const idToken = JSON.parse(localStorage.getItem("okta-token-storage"));
    this.setState({
      currentUserEmail: idToken.idToken.claims.email,
      currentUserName: idToken.idToken.claims.name,
    });
  }

  render() {
    const { currentUserEmail, currentUserName } = this.state;

    return (
      <div className="container pl-5 ml-2">
        <div className="container-fluid mb-1">
          <div className="text-center">
            <h2>Welcome {currentUserName}</h2>
            <span> {currentUserEmail}</span>
          </div>
          <div className="row">
            <div className="col-md-5">
              <a href="/providerdir">
                <div className="box">
                  <div className="our-services wrapper">
                    <AssignmentIndIcon></AssignmentIndIcon>
                    <h4> Provider Directory</h4>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
