import React, { useState } from "react";
import { Button, Segment, Label, Table } from "semantic-ui-react";
import { useNavigate } from "@reach/router";
import "../index.css";
const ProviderResults = ({
  firstName,
  lastName,
  confidenceScore,
  npiNumber,
  deaNumber,
  stateLicense,
  licenseType,
}) => {
  const [expanded, setExpanded] = useState(false);

  const name = firstName + " " + lastName;
  const score = confidenceScore >= 0.95 ? "green" : "red";
  const npi = npiNumber !== "" ? npiNumber : "NA";
  const dea = deaNumber !== "" ? deaNumber : "NA";
  const license = stateLicense !== "" ? stateLicense : "NA";
  const confScore = confidenceScore;

  return (
    <>
      <div
        style={{
          backgroundColor: "var(--background)",
          color: "var(--text-primary)",
        }}
        className="ui raised card fluid"
      >
        <div onClick={() => setExpanded(!expanded)}>
          <table
            style={{
              backgroundColor: "var(--background)",
              color: "var(--text-primary)",
            }}
            className="ui blue table fixed celled singleLine"
          >
            <thead
              style={{
                backgroundColor: "var(--background)",
                color: "var(--text-primary)",
              }}
            >
              <tr
                style={{
                  backgroundColor: "var(--background)",
                  color: "var(--text-primary)",
                }}
              >
                <th
                  style={{
                    backgroundColor: "var(--background)",
                    color: "var(--text-primary)",
                  }}
                >
                  {" "}
                  Provider Name : <div>{name.toUpperCase()}</div>
                </th>
                <th
                  style={{
                    backgroundColor: "var(--background)",
                    color: "var(--text-primary)",
                  }}
                >
                  {" "}
                  NPI number:
                  <div>{npi}</div>
                </th>
                <th
                  style={{
                    backgroundColor: "var(--background)",
                    color: "var(--text-primary)",
                  }}
                >
                  {" "}
                  DEA number:
                  <div>{dea.toUpperCase()}</div>
                </th>
                <th
                  style={{
                    backgroundColor: "var(--background)",
                    color: "var(--text-primary)",
                  }}
                >
                  {" "}
                  State License number :{" "}
                  <div>
                    {license} {licenseType !== "NA" ? `(${licenseType})` : ""}
                  </div>
                </th>
                <th
                  style={{
                    backgroundColor: "var(--background)",
                    color: "var(--text-primary)",
                  }}
                >
                  {expanded ? (
                    <button
                      style={{ color: "var(--accent)" }}
                      className="btn"
                      onClick={() => setExpanded(!expanded)}
                    >
                      <i class="minus circle icon"></i> Hide
                    </button>
                  ) : (
                    <button
                      style={{ color: "var(--accent)" }}
                      className="btn"
                      onClick={() => setExpanded(!expanded)}
                    >
                      <i className="plus circle icon"></i>
                      More Info{" "}
                    </button>
                  )}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        {expanded && (
          <>
            <div
              className="content"
              style={{
                backgroundColor: "var(--background)",
                color: "var(--text-primary)",
              }}
            >
              <span
                style={{
                  backgroundColor: "var(--background)",
                  color: "var(--text-primary)",
                }}
                className="content"
              >
                Provider Name :
              </span>
              <span
                style={{
                  backgroundColor: "var(--background)",
                  color: "var(--text-primary)",
                }}
                className="header"
              >
                {" "}
                {name.toUpperCase()}
              </span>
            </div>
            <div className="extra content">
              <div
                style={{
                  backgroundColor: "var(--background)",
                  color: "var(--text-primary)",
                }}
                className="content"
              >
                {" "}
                Confidence Score :{" "}
                <Button
                  style={{ marginLeft: "15px" }}
                  color={score}
                  size="small"
                >
                  {" "}
                  <div>{confScore}</div>
                </Button>
              </div>
            </div>

            <div
              style={{
                backgroundColor: "var(--background)",
                color: "var(--text-primary)",
              }}
              className="extra content"
            >
              <div className="ui large horizontal divided list">
                <div className="item">
                  <div className="content">
                    <div className="content small">NPI Number </div>
                    <span
                      style={{
                        backgroundColor: "var(--background)",
                        color: "var(--text-primary)",
                      }}
                      className="header"
                    >
                      {npi}
                    </span>
                  </div>{" "}
                </div>
                <div className="item">
                  <div className="content">
                    <div className="content small">DEA Number</div>
                    <span
                      style={{
                        backgroundColor: "var(--background)",
                        color: "var(--text-primary)",
                      }}
                      className="header"
                    >
                      {dea.toUpperCase()}
                    </span>
                  </div>
                </div>
                <div className="item">
                  <div className="content">
                    <div className="content small">State License Number</div>
                    <span
                      style={{
                        backgroundColor: "var(--background)",
                        color: "var(--text-primary)",
                      }}
                      className="header"
                    >
                      {license}
                    </span>
                  </div>{" "}
                </div>
                <div className="item">
                  <div className="content">
                    <div className="content small">State License type</div>
                    <span
                      style={{
                        backgroundColor: "var(--background)",
                        color: "var(--text-primary)",
                      }}
                      className="header"
                    >
                      {licenseType}
                    </span>
                  </div>{" "}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const styles = {
  pullRight: {
    float: "right",
  },
  red: {
    color: "#f85959",
  },
  greenBlue: {
    color: "#278ea5",
  },
  iconWidth: {
    width: "1em",
  },
};

export default ProviderResults;
