import React, { useState } from "react";
import { useNavigate } from "@reach/router";
import {
  Header,
  Divider,
  Form,
  Message,
  Group,
  Segment,
  Label,
} from "semantic-ui-react";
import "../index.css";

const ProviderSearch = () => {
  const [fnameKeyword, setFnameKeyWord] = useState("");
  const [lnameKeyword, setLnameKeyWord] = useState("");
  const [npiNumberKeyword, setNpiNumberKeyWord] = useState("");
  const [deaNumberKeyword, setDeaNumberKeyWord] = useState("");
  const [licenseNumberKeyword, setLicenseNumberKeyWord] = useState("");
  const navigate = useNavigate();

  const fnameKeywordInputHandler = (e) => {
    setFnameKeyWord(e.target.value);
  };

  const lnameKeywordInputHandler = (e) => {
    setLnameKeyWord(e.target.value);
  };

  const npiKeywordInputHandler = (e) => {
    setNpiNumberKeyWord(e.target.value);
  };
  const deaKeywordInputHandler = (e) => {
    setDeaNumberKeyWord(e.target.value);
  };
  const licenseKeywordInputHandler = (e) => {
    setLicenseNumberKeyWord(e.target.value);
  };
  const searchButtonHandler = (e) => {
    e.preventDefault();
    const npiNumberF = npiNumberKeyword === "" ? null : npiNumberKeyword;
    const deaNumberF = deaNumberKeyword === "" ? null : deaNumberKeyword;
    const stateLicenseNumberF =
      licenseNumberKeyword === "" ? null : licenseNumberKeyword;
    const arr = {
      fnameKeyword,
      lnameKeyword,
      npiNumberF,
      deaNumberF,
      stateLicenseNumberF,
    };
    console.log(arr);
    navigate(
      `/results/${fnameKeyword}/${lnameKeyword}/${npiNumberF}/${deaNumberF}/${stateLicenseNumberF}`
    );
  };

  const styles = {
    bottomMargin: {
      marginBottom: "1em",
    },
    textCenter: {
      textAlign: "center",
    },
  };

  return (
    <div
      style={{
        backgroundColor: "var(--background)",
        color: "var(--text-primary)",
      }}
    >
      <Header
        style={{ color: "var(--text-primary)" }}
        as="h1"
        textAlign="center"
      >
        Provider Search
      </Header>
      <Divider />
      <Message
        warning
        header="Note"
        content="You need to atleast enter first name and last name to search for providers."
      />
      <Segment
        style={{
          backgroundColor: "var(--background)",
          color: "var(--text-primary)",
        }}
      >
        <Form warning onSubmit={searchButtonHandler}>
          <Form.Group widths="equal">
            <Form.Field required>
              <label style={{ color: "var(--text-primary)" }}>First Name</label>
              <Form.Input
                fluid
                placeholder="First name"
                value={fnameKeyword}
                onChange={fnameKeywordInputHandler}
                type="text"
                maxLength={25}
                required
              />
            </Form.Field>
            <Form.Field required>
              <label style={{ color: "var(--text-primary)" }}>Last Name</label>
              <Form.Input
                fluid
                placeholder="Last name"
                value={lnameKeyword}
                type="text"
                maxLength={25}
                onChange={lnameKeywordInputHandler}
                required
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label style={{ color: "var(--text-primary)" }}>
                {" "}
                NPI number
              </label>
              <Form.Input
                placeholder="NPI number"
                fluid
                style={styles.bottomMargin}
                value={npiNumberKeyword}
                onChange={npiKeywordInputHandler}
                type="text"
                maxLength={25}
              />
            </Form.Field>
            <Form.Field>
              <label style={{ color: "var(--text-primary)" }}>
                {" "}
                DEA number
              </label>
              <Form.Input
                fluid
                placeholder="DEA number"
                style={styles.bottomMargin}
                value={deaNumberKeyword}
                onChange={deaKeywordInputHandler}
                type="text"
                maxLength={25}
              />
            </Form.Field>
            <Form.Field>
              <label style={{ color: "var(--text-primary)" }}>
                {" "}
                State License number
              </label>
              <Form.Input
                fluid
                placeholder="State license number"
                style={styles.bottomMargin}
                value={licenseNumberKeyword}
                onChange={licenseKeywordInputHandler}
                type="text"
                maxLength={25}
              />
            </Form.Field>
          </Form.Group>
          <div style={styles.textCenter}>
            <Form.Button
              fluid
              style={{
                height: "50px",
                fontSize: "20px",
                marginTop: "30px",
                paddingBottom: "30px",
                backgroundColor: "var(--accent)",
              }}
              primary
            >
              Search
            </Form.Button>
          </div>
        </Form>
      </Segment>
    </div>
  );
};

export default ProviderSearch;
