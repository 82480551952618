import React, { useState, useEffect } from "react";
import { Card } from "semantic-ui-react";
import ProviderResults from "./ProviderResults";
import { IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory } from "react-router-dom";
import { useNavigate } from "@reach/router";
import LinearProgress from "@mui/material/LinearProgress";
import { useOktaAuth } from "@okta/okta-react";

const ProviderSearchResults = (props) => {
  const [filterdProviders, setFilterdProviders] = useState([]);
  const [records, setRecords] = useState("");
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState("");
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;

  const [isLoading, setLoading] = useState(true);

  let history = useHistory();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  const requestData = (e) => {
    try {
      fetch(
        "https://6tokqv3309.execute-api.us-east-2.amazonaws.com/v1/filter",
        {
          method: "POST",
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            firstName: props.firstName,
            lastName: props.lastName,
            deaNumber: props.deaNumber,
            stateLicense: props.stateLicense,
            npiNumber: props.npiNumber,
          }),
        }
      )
        .then(function (response) {
          console.log(response);
          return response.json();
        })
        .then(function (myJson) {
          console.log(myJson);
          setFilterdProviders(myJson.items);
          setRecords(myJson.count);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    requestData();
  }, []);

  function goBack(e) {
    e.preventDefault();
    navigate("/providerdir");
  }
  if (isLoading) {
    return <LinearProgress />;
  } else if (Array.isArray(filterdProviders) && filterdProviders.length) {
    // console.log(filterdProviders);
    return (
      <>
        <div className="ui clearing segment">
          <h3 className="ui center floated header">
            <IconButton onClick={goBack}>
              <ChevronLeftIcon fontSize="large" />
            </IconButton>
          </h3>
          <h4 className="ui center aligned header">
            {"Total number of providers found : " + records}
          </h4>
        </div>

        <Card.Group>
          {filterdProviders.map((trip) => {
            return <ProviderResults {...trip} />;
          })}
        </Card.Group>
      </>
    );
  } else {
    return (
      <>
        <button
          onClick={() => history.goBack()}
          style={{ textAlign: "center" }}
          className="ui labeled icon button"
        >
          <i className="left arrow icon"></i>
          Go back to the provider search
        </button>
        <Card fluid>
          <Card.Content>
            <Card.Header textAlign="center">No results found</Card.Header>
          </Card.Content>
        </Card>
      </>
    );
  }
};
const styles = {
  pullRight: {
    float: "right",
  },
  red: {
    color: "#f85959",
  },
  greenBlue: {
    color: "#278ea5",
  },
  iconWidth: {
    width: "1em",
  },
};

export default ProviderSearchResults;
