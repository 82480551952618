/* eslint-disable */
import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Drawer from "@material-ui/core/Drawer";
import "../layout/Navbar.css";
import { Button } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { yellow } from "@material-ui/core/colors";
import { Message } from "semantic-ui-react";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginInlineEnd: 7,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "var(--background)",
    fontColor: "var(--text-primary)",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    whiteSpace: "nowrap",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8),
    },
    backgroundColor: "var(--background)",
    fontColor: "var(--text-primary)",
  },
  toolbar: {
    display: "flex",
    alignItems: "left",
    justifyContent: "flex-end",
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[800],
    "&:hover": {
      backgroundColor: yellow[900],
    },
  },
}))(Button);

const Navbar = ({ setCorsErrorModalOpen }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Note: Can't distinguish CORS error from other network errors
  const isCorsError = (err) =>
    err.name === "AuthApiError" &&
    !err.errorCode &&
    err.xhr.message === "Failed to fetch";

  const login = async () =>
    oktaAuth.signInWithRedirect({ originalUri: "/menu" });

  const logout = async () => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  if (!authState) {
    return null;
  }

  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          style={{
            textDecoration: "none",
            background: "linear-gradient(45deg, #454605 30%, #093979 90%)",
          }}
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={process.env.PUBLIC_URL + "/CHicon.png"}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt=""
            ></img>

            <Typography className="navbar-brand p-2">CyncHealth</Typography>
            <span className="example-fill-remaining-space"></span>
            <Typography
              id="myHeading"
              variant="h6"
              fontWeight="fontWeightBold"
              style={{ textShadow: "2px 4px 3px rgba(0,0,0,0.3)" }}
            >
              Provider Directory
            </Typography>

            <span className="example-fill-remaining-space"></span>

            {/* {authState.isAuthenticated && (
              <Link to="/profile"></Link>
            
          )} */}
            {authState.isAuthenticated && (
              <ColorButton
                variant="contained"
                color="primary"
                endIcon={<PersonIcon></PersonIcon>}
                id="logout-button"
                onClick={logout}
              >
                Logout
              </ColorButton>
            )}
            {!authState.isPending && !authState.isAuthenticated && (
              <ColorButton
                variant="contained"
                color="primary"
                endIcon={<PersonIcon></PersonIcon>}
                id="logout-button"
                onClick={login}
              >
                Login
              </ColorButton>
            )}
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List
            style={{
              backgroundColor: "var(--background)",
              color: "var(--text-primary)",
            }}
            onMouseOver={handleDrawerOpen}
            onMouseLeave={handleDrawerClose}
          >
            <a href="/menu" style={{ textDecoration: "none" }}>
              <ListItem button>
                <Tooltip
                  disableFocusListener
                  title="Menu"
                  placement="top-end"
                  arrow
                >
                  <ListItemIcon
                    style={{
                      color: "var(--text-primary)",
                    }}
                  >
                    <DashboardIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText> Menu</ListItemText>
              </ListItem>
            </a>

            <a href="/providerdir" style={{ textDecoration: "none" }}>
              <ListItem button>
                <Tooltip
                  disableFocusListener
                  title="Provider Directory"
                  placement="top-end"
                  arrow
                >
                  <ListItemIcon
                    style={{
                      color: "var(--text-primary)",
                    }}
                  >
                    {" "}
                    <AssignmentIndIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText>Provider Directory</ListItemText>
              </ListItem>
            </a>
          </List>
          <Divider />
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.toolbar} />
          <div className={classes.drawerHeader}></div>
          {!authState.isPending && !authState.isAuthenticated && (
            <Message
              warning
              header="Please click on the Login button to access!"
              list={[
                "You will be redirected to CyncHealth official login page to complete the login process.",
              ]}
            />
          )}
        </main>
      </div>
    </div>
  );
};
export default Navbar;
