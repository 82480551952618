/* eslint-disable */
import React from "react";
import { Route, useHistory, Switch } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import config from "./config";
import Navbar from "./components/layout/Navbar";
import CorsErrorModal from "./CorsErrorModal";
import ProviderSearch from "./screens/ProviderSearch";
import { Router } from "@reach/router";
import ProviderSearchResults from "./screens/ProviderSearchResults";
import ProviderResults from "./screens/ProviderResults";
import { Container } from "semantic-ui-react";
import "./index.css";
import useLocalStorage from "use-local-storage";
import Menu from '././components/pages/menu'

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const defaultDark = window.matchMedia(
    "(prefers-color-scheme: light)"
  ).matches;

  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const history = useHistory();
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "light" : "dark"
  );

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const styles = {
    containerPadding: {
      paddingTop: "2em",
      paddingBottom: "2em",
    },
  };

  const switchTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App" data-theme={theme}>
        <Navbar {...{ setCorsErrorModalOpen }} />

        <div className="theme-button toggle-theme-wrapper">
          <span>☀️</span>
          <label className="toggle-theme" htmlFor="checkbox">
            <input
              checked={theme === "dark" ? true : false}
              type="checkbox"
              id="checkbox"
              onClick={switchTheme}
            />
            <div className="slider round"></div>
          </label>
          <span>🌒</span>
        </div>
        <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
        <Switch>
          <div className="container">
            <Route path="/" exact component={LoginCallback} />
            <Route path="/login/callback" component={LoginCallback} />
            <SecureRoute exact={true} path="/menu" component={Menu} />
            {/* <SecureRoute path="/providerdir" exact={true} component={DataTable} /> */}
          </div>
        </Switch>
        <Container style={styles.containerPadding}>
          <SecureRoute>
            <Router>
              {/* <SecureRoute
                path="/playground"
                exact={true}
                component={Playground}
              /> */}
              {/* <SecureRoute path="/results" exact={true} component={Results} /> */}

              <ProviderResults exact path="trip/:clusterID" />
              <ProviderSearch exact path="/providerdir" />
              <ProviderSearchResults
                exact
                path="results/:firstName/:lastName/:npiNumber/:deaNumber/:stateLicense"
              />
            </Router>
          </SecureRoute>
        </Container>
      </div>
    </Security>
  );
};

export default App;
